.footer {
  padding: 80px 0 40px;
  background: #131313 url('../../../assets/images/bg-footer.png') no-repeat right bottom/cover;
  color: #ffffff;
  overflow: hidden;
  &-title {
    font-weight: 700;
    font-size: 40px;
    line-height: 100%;
    display: inline-block;
    position: relative;
    &::after {
      content: '';
      width: 56px;
      border-top: 1px solid var(--red-color);
      position: absolute;
      top: 50%;
      right: -76px;
    }
    &:hover {
      color: white !important;
    }
  }
  &-middle {
    margin: 60px 0 80px;
    .row {
      --bs-gutter-x: 50px;
      --bs-gutter-y: 50px;
      & > div {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        &:first-child {
          -webkit-box-pack: start;
          -ms-flex-pack: start;
          justify-content: flex-start;
        }
      }
    }
  }
  &-column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 14px;
    line-height: 100%;
    &--contacts {
      .footer-column__link {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        svg {
          margin-right: 4px;
        }
      }
      .footer-column__subtitle {
        margin-bottom: 16px;
      }
      div:first-of-type .footer-column__subtitle {
        margin-top: 12px;
      }
    }
    &--socials {
      height: 100%;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      .footer-column__link {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        svg {
          margin-right: 6px;
        }
      }
    }
    &__title {
      font-weight: 500;
      margin-bottom: 8px;
    }
    &__subtitle {
      margin: 20px 0 4px;
    }
    &__link {
      font-weight: 300;
      opacity: 0.6;
      margin-top: 12px;
    }
  }
  &-logo {
    height: 48px;
    margin-top: 50px;
    img {
      height: 100%;
      -o-object-fit: contain;
      object-fit: contain;
    }
  }
  &-bottom {
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    opacity: 0.5;
    padding-top: 40px;
    position: relative;
    &:before {
      content: '';
      opacity: 0.1;
      width: 100%;
      border: 1px solid #ffffff;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
.moore-logo {
  height: 30px;
  margin-top: 50px;
  img {
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
  }
}

@media (max-width: 768px) {
  .footer {
    background-size: auto 70%;
    &-middle .row > div {
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
    }
  }
}

@media (max-width: 500px) {
  .footer {
    padding: 50px 0 30px;

    &-logo {
      height: 40px;
      margin-top: 30px;
    }
    &-title {
      font-size: 36px;
      &::after {
        display: none;
      }
    }
    &-middle {
      margin: 40px 0;
    }
    &-bottom {
      padding-top: 30px;
    }
  }
}
