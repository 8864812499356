.section-slider-news {
  background-color: #f8f8f8;
  background-image: url('../../../../assets/images/bg-news1.svg'),
    url('../../../../assets/images/bg-news2.svg');
  background-position: left top;
  background-repeat: no-repeat;
  background-size: auto, cover;
  &__wrap {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .container {
    max-width: 1440px;
    padding-right: 0;
  }
  .section-title {
    padding-left: 60px;
    width: 197px;
    margin-right: 30px;
  }
  .section-slider__wrapper {
    flex: 1;
    width: 100%;
    padding: 0;
  }
  .section-slider__swiper {
    max-width: 970px;
  }
}

@media (max-width: 1400px) {
  .section-slider-news {
    .container {
      max-width: 1310px;
      padding-right: 15px;
    }
    .section-title {
      width: auto;
      padding-left: initial;
    }
  }
}
