.productCard {
  background: #ffffff;
  padding: 30px;
  border-radius: 5px;
  height: 100%;
  &-img {
    height: 167px;
    border-radius: 5px;
    span {
      width: 100%!important;
      height: 100%!important;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    &__wrap {
      position: relative;
      display: inline-block;
    }
  }
  &-promo {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 5px;
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
    color: #fff;
    padding: 3px 7px 4px;
  }
  &-status {
    font-weight: 300;
    font-size: 12px;
    line-height: 100%;
    margin: 10px 0 6px;
    display: inline-block;
  }
  &-name {
    font-weight: 300;
    font-size: 14px;
    line-height: 130%;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 36.4px;
  }
  &-price {
    font-weight: 500;
    font-size: 20px;
    line-height: 100%;
    margin: 10px 0 17px;
    &-old {
      font-weight: 500;
      font-size: 12px;
      line-height: 100%;
      text-decoration-line: line-through;
      color: #7d7d7d;
      display: inline-block;
      margin-left: 2px;
    }
    &--red {
      color: var(--red-color);
    }
  }
  &-bottom {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: -10px;
  }
  &-counter {
    display: flex;
    justify-content: space-between;
    width: 56px;
    height: 20px;
    background: #f7f7f7;
    margin-right: 10px;
    margin-top: 10px;
    font-weight: 300;
    font-size: 14px;
    line-height: 100%;
    button {
      padding: 0 6px;
    }
    span {
      display: flex;
      align-items: center;
    }
  }
  &-btn {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    --tw-text-opacity: 1;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
    padding-bottom: 6px;
    border-bottom: 1px solid var(--black-color);
    margin-top: 10px;
    transition: all 0.3s;
    &:hover {
      border-color: var(--red-color);
    }
    &:active {
      border-color: var(--red-darken-color);
    }
  }
}
