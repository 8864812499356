.section-slider-promotionsSlider {
  background-image: url('../../../../assets/images/bg-sales.svg');
  background-position: left;
  background-repeat: no-repeat;
}
.promotions__slider-img {
  height: 236px;
  cursor: pointer;
  span {
    height: 100%!important;
    max-width: 100%!important;
  }
  img {
    height: 100%;
    object-fit: contain;
    object-position: left;
    border-radius: 5px;
    width: 100%;
  }
}
