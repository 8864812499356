.contacts {
  &-banner {
    background: #f8f8f8;
    padding: 2.5rem;
    &__wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: -20px;
    }
    &__text {
      font-weight: 300;
      font-size: 15px;
      line-height: 150%;
      opacity: 0.8;
      max-width: 460px;
      margin-right: 20px;
      margin-top: 20px;
      flex: 1;
      min-width: 230px;
    }
    &__right {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-top: 20px;
    }
    &__socials {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 18px;
      margin-top: -10px;
      p {
        font-weight: 500;
        font-size: 14px;
        line-height: 100%;
        margin-right: 20px;
        margin-top: 10px;
      }
      a {
        font-weight: 300;
        font-size: 14px;
        line-height: 100%;
        display: flex;
        align-items: center;
        margin-top: 10px;
        &:not(:last-of-type) {
          margin-right: 20px;
        }
        svg {
          margin-right: 6px;
        }
      }
    }
    .contacts__link-download {
      margin-left: auto;
    }
  }
  &-bottom {
    padding-bottom: 6rem;
    .container {
      display: flex;
      align-items: flex-start;
    }
  }
  &__link-download {
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    color: var(--red-color);
    display: flex;
    align-items: center;
    svg {
      margin-left: 14px;
      transition: transform 0.3s;
    }
    &:hover {
      svg {
        transform: translate(0, 2px);
      }
    }
  }
  .branches {
    padding-bottom: 5rem;
  }
}
