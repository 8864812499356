.news {
  &-grid {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    justify-content: center;
    gap: 2rem;
    padding-top: 3.75rem;
  }
  padding-bottom: 6rem;
  &-title {
    font-size: 24px;
    min-height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 1024px) {
  .news-grid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
@media (max-width: 768px) {
  .news-grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media (max-width: 640px) {
  .news-grid {
    gap: 1rem;
  }
}
