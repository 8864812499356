.hero {
  background: url('../../../../assets/images/bg-hero.png') no-repeat bottom right/contain;
  .container {
    min-height: calc(700px - 142px);
    position: relative;
  }
  &__content {
    max-width: 524px;
    padding-top: 5rem;
  }
  &-title {
    font-family: 'PragmaticaCondCTT', sans-serif;
    font-size: 60px;
    line-height: 100%;
  }
  &-subtitle {
    font-weight: 300;
    font-size: 14px;
    line-height: 150%;
    opacity: 0.8;
    max-width: 323px;
    margin: 30px 0;
  }
  &-link {
    font-size: 15px;
    line-height: 100%;
    svg {
      margin-left: 16px;
    }
  }
  &-btn-phone {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    box-shadow: 20px 20px 80px rgba(0, 0, 0, 0.3);
    position: absolute;
    right: 15px;
    bottom: 50px;
    transition: background 0.3s;
    svg {
      width: 24px;
      height: 24px;
    }
    &:hover {
      background: #fff1f1;
    }
  }
}

@media (max-width: 991px) {
  .hero {
    background: url('../../../../assets/images/bg-hero-mobile.png') no-repeat bottom right/contain;
  }
}

@media (max-width: 768px) {
  .hero {
    .container {
      min-height: calc(100vh - 64px);
    }
    &-title {
      font-size: 48px;
    }
    &-subtitle {
      margin: 20px 0;
    }
    &-btn-phone {
      display: none;
    }
  }
}
@media (max-width: 500px) {
  .hero-title {
    font-size: 30px;
  }
}
