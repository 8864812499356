.productPage {
  &__wrap {
    display: flex;
  }
  &__slider {
    max-width: 542px;
    width: 100%;
    margin-right: 48px;
  }
  &__content {
    .custom-counter {
      width: 73px;
      height: 26px;
      font-size: 18px;
      margin: 20px 0;
    }
  }
  &__link-back {
    display: flex;
    align-items: center;
    font-weight: 300;
    font-size: 14px;
    line-height: 150%;
    opacity: 0.5;
    svg {
      margin-right: 5px;
    }
  }
  &-title {
    font-family: var(--second-font), sans-serif;
    font-weight: 400;
    font-size: 30px;
    line-height: 100%;
    max-width: 332px;
    margin-top: 10px;
  }
  &-subtitle {
    font-weight: 400;
    font-size: 15px;
    line-height: 100%;
    margin: 30px 0 20px;
  }
  &-text {
    font-weight: 300;
    font-size: 14px;
    line-height: 150%;
    opacity: 0.8;
    max-width: 440px;
  }
  &-code {
    font-weight: 400;
    font-size: 15px;
    line-height: 100%;
    margin-top: 15px;
    span {
      font-weight: 300;
      font-size: 14px;
      opacity: 0.8;
      margin-left: 5px;
    }
  }
  &__content-bottom {
    display: flex;
    align-items: flex-end;
    margin-bottom: 30px;
  }
  &-price {
    font-weight: 400;
    font-size: 30px;
    line-height: 100%;
    margin-right: 14px;
  }
  &-available {
    font-weight: 400;
    font-size: 15px;
    line-height: 100%;
    color: #74be84;
    margin-bottom: 3px;
  }
  &-btn-cart {
    background: #ea5a5a;
    border-radius: 5px;
    width: 159px;
    height: 47px;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s;
    svg {
      stroke: white;
      stroke-width: 2px;
      width: 14px;
      height: 14px;
      margin-left: 5px;
    }
    &:hover {
      opacity: 0.9;
    }
  }
  &__desc {
    padding: 5rem 0 8rem;
    &-head {
      font-weight: 400;
      font-size: 15px;
      line-height: 100%;
      padding: 23px 30px 22px;
      background: #f2f2f2;
      margin-bottom: 40px;
    }
    &-list {
      padding: 0 1.9rem;
      &__item {
        font-weight: 400;
        font-size: 15px;
        line-height: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-top: -5px;
        &:not(:last-of-type) {
          margin-bottom: 16px;
        }
        p {
          opacity: 0.6;
          min-width: 180px;
          margin-right: 2.3rem;
          margin-top: 5px;
        }
        span {
          margin-top: 5px;
        }
      }
    }
  }
}

.section-slider-product-page {
  padding: 0 0 6rem;
}

@media (max-width: 991px) {
  .productPage {
    &__wrap {
      flex-direction: column;
    }
    &-title {
      max-width: 100%;
    }
    &-text {
      max-width: 100%;
    }
    &__slider {
      max-width: 100%;
      margin-bottom: 30px;
    }
  }
}
@media (max-width: 560px) {
  .productPage__desc-list {
    padding: 0 1rem;
  }
}
