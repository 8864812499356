.catalog {
  padding-bottom: 6rem;
  &-wrap {
    display: flex;
  }
  &-left {
    width: 100%;
    max-width: 294px;
    margin-right: 40px;
  }
  &-right {
    flex: 1;
  }
  &-filter {
    &__container {
      display: none;
    }
    &-header {
      display: none;
    }
    .accordion {
      &-item {
        border: none;
        &:not(:last-of-type) {
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }
        border-radius: 5px;
      }
      &-button {
        font-weight: 500;
        font-size: 15px;
        line-height: 100%;
        padding: 10px 20px;
        border: none;
        box-shadow: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #f5f5f5;
        border-radius: 5px;
        &::after {
          display: none;
        }
        svg {
          transition: transform 0.3s;
        }
        &:focus {
          box-shadow: none;
        }
        &:hover {
          color: var(--red-color);
        }
        &:not(.collapsed) {
          background: var(--pink-color);
          color: white !important;
          svg {
            transform: rotate(180deg);
            stroke: white;
          }
        }
      }
      &-body {
        display: flex;
        flex-direction: column;
        padding: 10px 20px 10px 40px;
        .catalog-filter__subItem {
          a {
            font-weight: 400;
            font-size: 15px;
            line-height: 100%;
          }
          padding: 10px 20px;
          &:not(:last-of-type) {
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          }
          &--active {
            background: #f0f0f0;
            border-color: transparent !important;
            position: relative;
            border-radius: 5px;
            span {
              position: relative;
            }
            &::before {
              content: '';
              width: 100%;
              height: 100%;
              background: #f0f0f0;
              position: absolute;
              top: -3px;
              left: 0;
              border-radius: 5px;
            }
          }
        }
      }
    }
    &__subCat {
      &-link {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 20px;
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        span {
          font-weight: 400;
          font-size: 12px;
          line-height: 100%;
          opacity: 0.5;
          margin-top: 2px;
          margin-left: 3px;
        }
      }
      .accordion {
        &-item {
          border: none !important;
          &:not(:last-of-type) {
            margin-bottom: 20px;
          }
        }
        &-button {
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          background: none;
          padding: 0;
          &:not(.collapsed) {
            background: none;
            color: inherit !important;
            svg {
              stroke: var(--black-color);
            }
          }
        }
        &-body {
          padding: 20px 0 0 20px;
        }
      }
    }
    &__subSubCat {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      &-link {
        font-weight: 300;
        font-size: 15px;
        line-height: 100%;
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        &:not(:last-of-type) {
          margin-bottom: 10px;
        }
        span {
          font-weight: 400;
          font-size: 12px;
          line-height: 100%;
          opacity: 0.5;
          margin-top: 2px;
          margin-left: 3px;
        }
      }
    }
  }
  &-categories {
    height: 87px;
    padding: 30px 0 40px;
    &__list {
      display: flex;
      flex-wrap: wrap;
      gap: 8px 20px;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #5a84d5;
    }
  }
  &-banner {
    background: url('../../assets/images/example-banner.png') no-repeat right/cover;
    padding: 27px 34px;
    border-radius: 5px;
    margin-bottom: 2.5rem;
    &__title {
      font-weight: 400;
      font-size: 27px;
      line-height: 100%;
      color: #ffffff;
      max-width: 403px;
      margin-bottom: 18px;
    }
    &__link {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 115px;
      height: 34px;
      font-size: 15px;
      line-height: 100%;
      color: #505050;
      background: #ffffff;
      border-radius: 5px;
    }
  }
  &-results {
    &__title {
      font-weight: 400;
      font-size: 40px;
      line-height: 100%;
    }
    &__btn-categories {
      background: var(--red-color);
      border-radius: 5px;
      width: 123px;
      height: 28px;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: white;
      margin-top: 20px;
      display: none;
      justify-content: center;
      align-items: center;
      transition: background .3s;
      svg {
        stroke: white;
        margin-left: 8px;
      }
      &:hover, &:active {
        background: #d2151b;
      }
    }
    &__right {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 30px 0 42px;
    }
    &__count {
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.01em;
      opacity: 0.5;
    }
    &__grid {
      display: grid;
      grid-template-columns: repeat(4, minmax(0, 1fr));
      justify-content: center;
      gap: 1.7rem;
      .productCard {
        padding: 18px;
      }
    }
  }
}

@media (max-width: 1200px) {
  .catalog-results__grid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
@media (max-width: 991px) {
  .catalog {
    .breadcrumbs {
      padding-bottom: 0;
    }
    &-categories {
      height: auto;
      padding: 8px 0 16px;
    }
    &-wrap {
      flex-direction: column;
    }
    &-results {
      &__header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;
      }
      &__btn-categories {
        display: flex;
      }
      &__left {
        flex: 1;
      }
      &__right {
        position: relative;
        align-items: flex-start;
        margin: 10px 0 0 10px;
        .custom-select {
          position: absolute;
          right: 0;
          bottom: 6px;
        }
      }
      &__grid {
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }
    }
    &-filter {
      position: fixed;
      top: 62px;
      right: -100%;
      background: #ffffff;
      border-radius: 6px;
      z-index: 12;
      padding: 16px;
      transition: all 0.5s;
      overflow: auto;
      max-height: calc(100vh - 64px);
      width: 100%;
      max-width: 430px;
      &__container {
        &--show {
          display: block;
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 11 ;
        }
      }
      &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        &__title {
          background: #eef1f4;
          border-radius: 5px;
          font-weight: 500;
          font-size: 13px;
          line-height: 16px;
          padding: 6px 29px 6px 28px;
        }
        &__btn {
          svg {
            width: 24px;
            height: 24px;
            stroke: rgba(0, 0, 0, 0.5);
          }
        }
      }
      &--show {
        right: 0;
      }
    }
  }
}
@media (max-width: 767px) {
  .catalog {
  }
}
@media (max-width: 640px) {
  .catalog {
    &-results__grid {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-gap: 8px;
      .productCard {
        padding: 16px;
        &-img {
          height: 144px;
        }
      }
    }
    &-banner {
      padding: 16px;
      position: relative;
      overflow: hidden;
      &__title {
        font-size: 14px;
        margin-bottom: 12px;
        max-width: 210px;
        position: relative;
      }
      &__link {
        width: 110px;
        height: 28px;
        position: relative;
      }
      &::before {
        content: '';
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: -30%;
        clip-path: polygon(0 0, 100% 0, 81% 100%, 0% 100%);
        background: linear-gradient(98.2deg, #da4646 -39.7%, #24a9ec 82.88%);
      }
    }
    &-results {
      &__title {
        font-size: 30px;
      }
    }
  }
}
@media (max-width: 360px) {
  .catalog-results__btn-categories {
    width: 100px;
  }
}
