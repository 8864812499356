.modal-shipment__body {
  padding: 20px;
  &-title {
    font-size: 20px;
    margin-bottom: 20px;
    font-weight: 500;
  }
  &-text {
    line-height: 1.2;
    font-size: 16px;
  }
  &-btn-close {
    position: absolute;
    top: 20px;
    right: 25px;
    svg {
      width: 25px;
      height: 25px;
      stroke: #333333;
    }
  }
}