.news__card {
  width: 100%;
  background: white;
  display: inline-block;
  &-img {
    height: 221px;
    span {
      width: 100% !important;
      height: 100% !important;
    }
    img {
      display: block;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  &-bottom {
    padding: 1.9rem;
  }
  &-text {
    font-weight: 300;
    font-size: 15px;
    line-height: 130%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-bottom: 20px;
    color: var(--black-color) !important;
    height: 39px;
  }
  &-link {
    font-weight: 500;
    font-size: 15px;
    line-height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

@media (max-width: 640px) {
  .news__card {
    &-img {
      height: 180px;
    }
    &-bottom {
      padding: 10px;
    }
  }
}
