.promotions-modal   {
  &-dialog {
    max-width: 970px;
  }
  &__body {
    padding: 40px;
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
  }
  &__img {
    height: 425px;
    width: 425px;
    border-radius: 5px;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-radius: 5px;
    }
  }
  &__content {
    flex: 1;
  }
  &__date {
    font-weight: 400;
    font-size: 18px;
    line-height: 100%;
    color: var(--red-color);
    padding-right: 30px;
  }
  &__title {
    font-family: var(--second-font), sans-serif;
    font-weight: 400;
    font-size: 40px;
    line-height: 100%;
    margin: 30px 0 20px;
  }
  &__content-body {
    strong {
      font-weight: bold!important;
      * {
        font-weight: bold!important;
      }
    }
    em {
      font-style: italic!important;
      * {
        font-style: italic!important;
      }
    }
    p, span {
      font-weight: 300;
      font-size: 14px;
      line-height: 150%;
      color: rgba(51, 51, 51, 0.8);
    }
    h2, h3, h4, h5, h6 {
      font-family: var(--second-font), sans-serif;
      font-weight: 400;
      font-size: 20px;
      line-height: 100%;
      * {
        font-family: var(--second-font), sans-serif;
        font-weight: 400;
        font-size: 20px;
        line-height: 100%;
      }
    }
  }
  &__btn-close {
    position: absolute;
    top: 42px;
    right: 42px;
    svg {
      width: 24px;
      height: 24px;
      stroke: rgba(0, 0, 0, 0.5);
    }
  }
}

@media (max-width: 1200px) {
  .promotions-modal {
    &-dialog {
      max-width: 800px;
    }
    &__img {
      width: 300px;
      height: 300px;
    }
  }
}
@media (max-width: 1024px) {
  .promotions-modal {
    &-dialog {
      max-width: 600px;
    }
    &__body {
      flex-direction: column;
    }
    &__btn-close {
      top: 15px;
      right: 15px;
      border-radius: 50%;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: white;
    }
    &__date {
      padding-right: 0;
    }
  }
}
@media (max-width: 640px) {
  .promotions-modal {
    &__body {
      padding: 25px;
    }

    &__img {
      width: 250px;
      height: 250px;
    }

    &__title {
      font-size: 36px;
    }
  }
}
