@font-face {
  font-family: 'SF Pro Display';
  src: url('SFProDisplay-Medium.eot');
  src: local('SF Pro Display Medium'), local('SFProDisplay-Medium'),
    url('SFProDisplay-Medium.eot?#iefix') format('embedded-opentype'),
    url('SFProDisplay-Medium.woff2') format('woff2'),
    url('SFProDisplay-Medium.woff') format('woff'),
    url('SFProDisplay-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'SF Pro Display';
  src: url('SFProDisplay-Light.eot');
  src: local('SF Pro Display Light'), local('SFProDisplay-Light'),
    url('SFProDisplay-Light.eot?#iefix') format('embedded-opentype'),
    url('SFProDisplay-Light.woff2') format('woff2'),
    url('SFProDisplay-Light.woff') format('woff'),
    url('SFProDisplay-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'SF Pro Display';
  src: url('SFProDisplay-Bold.eot');
  src: local('SF Pro Display Bold'), local('SFProDisplay-Bold'),
    url('SFProDisplay-Bold.eot?#iefix') format('embedded-opentype'),
    url('SFProDisplay-Bold.woff2') format('woff2'), url('SFProDisplay-Bold.woff') format('woff'),
    url('SFProDisplay-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'SF Pro Display';
  src: url('SFProDisplay-Regular.eot');
  src: local('SF Pro Display Regular'), local('SFProDisplay-Regular'),
    url('SFProDisplay-Regular.eot?#iefix') format('embedded-opentype'),
    url('SFProDisplay-Regular.woff2') format('woff2'),
    url('SFProDisplay-Regular.woff') format('woff'),
    url('SFProDisplay-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'PragmaticaCondCTT';
  src: url('PragmaticaCondC.eot');
  src: url('PragmaticaCondC.eot?#iefix') format('embedded-opentype'),
    url('PragmaticaCondC.woff2') format('woff2'), url('PragmaticaCondC.woff') format('woff'),
    url('PragmaticaCondC.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
