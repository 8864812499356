.order-details {
  min-height: 300px;
  max-width: 800px;
  padding-top: 40px;
  padding-bottom: 40px;
  .section-title {
    display: block;
    text-align: center;
    margin: 0 auto;
  }
}