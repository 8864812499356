.promotions {
  padding-bottom: 6rem;
  &-title {
    font-size: 24px;
    min-height: 230px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-grid {
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    gap: 1.7rem;
    &__item {
      max-width: 236px;
      max-height: 236px;
      &-img {
        cursor: pointer;
        span {
          width: 100%!important;
          height: 100%!important;
        }
        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
          border-radius: 5px;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .promotions-grid {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}
@media (max-width: 768px) {
  .promotions-grid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
@media (max-width: 640px) {
  .promotions-grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 1rem;
  }
}
