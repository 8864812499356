.auth {
  &-form {
    max-width: 400px;
    margin: 0 auto;
    padding: 6rem 0;
    &__btn {
      &-submit {
        background: var(--pink-color);
        border-radius: 5px;
        font-weight: 500;
        font-size: 15px;
        line-height: 100%;
        color: #FFFFFF;
        padding: 10px 50px;
        display: flex;
        margin: 0 auto;
        transition: background .3s;
        &:hover {
          background: #e34f4f;
        }
        &:active {
          background: #d54a4a;
        }
      }
      &-recover {
        color: var(--red-color);
        display: flex;
        margin-left: auto;
        margin-top: 5px;
        font-size: 13px;
        transition: color .3s;
        &:hover {
          color: #d74045;
        }
        &:active {
          color: var(--red-darken-color);
        }
      }
    }
    &__bottom {
      margin-top: 20px;
      text-align: center;
      p {
        font-size: 16px;
        a {
          color: var(--red-color);
          &:hover {
            color: #e83e44!important;
          }
          &:active {
            color: var(--red-darken-color)!important;
          }
        }
      }
    }
    &__password {
      position: relative;
      &-btn {
        position: absolute;
        bottom: 10px;
        right: 10px;
      }
      input {
        font-size: 16px;
      }
      label {
        font-size: 16px;
      }
    }
    input {
      font-size: 16px;
    }
    label {
      font-size: 16px;
    }
    &__help {
      font-size: 14px;
    }
  }
  &-account-activation {
    min-height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    &__title {
      font-size: 24px;
      line-height: 1.1;
      text-align: center;
    }
  }
}

@media (max-width: 768px) {
  .auth-form__password-btn {
    bottom: 9px;
  }
}