.loader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  &--full-screen {
    position: fixed;
    top: 0;
    left: 0;
    min-height: 100vh;
    min-width: 100vw;
    background: #00000020;
    z-index: 10000;
  }
}

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
  &:after {
    content: ' ';
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid var(--pink-color);
    border-color: var(--pink-color) transparent var(--pink-color) transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
